import React, { ReactNode, HTMLProps } from 'react';
import { Button } from '@adamwebster/fused-components';
import styled from 'styled-components';
import { siteColors, styleVariables } from '../../styles/Variables';

const StyledButtonStyles = styled(Button)`
  border-radius: ${styleVariables.borderRadius};
  color: #fff;
  font-size: 1rem;
  padding: 10px 20px;
  height: auto;
`;

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  primary?: boolean;
}
const StyledButton = ({ children, ...rest }: Props) => {
  return (
    <StyledButtonStyles buttonColor={siteColors.primary} {...rest}>
      {children}
    </StyledButtonStyles>
  );
};

export default StyledButton;
