import React, { useState, useEffect } from 'react';
import SEO from '../components/seo';
import { Layout } from '../components/Layout';
import { HTag } from '../components/HTag';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
} from '@fortawesome/free-brands-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import {
  FormField,
  Input,
  Alert,
  useToast,
  Textarea,
} from '@adamwebster/fused-components';
import { StyledButton } from '../components/StyledButton';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { darken, tint, lighten } from 'polished';
import { CartItem } from '../components/CartItem';
import { LinkButton } from '../components/LinkButton';
import { styleVariables } from '../styles/Variables';

const StyledPaymentPage = styled.section`
  display: flex;
  @media (max-width: 767px) {
    flex-flow: column;
  }
`;

const StyledOrderReview = styled.div`
  flex: 0 0 300px;
  margin-right: 20px;
  background-color: #fff;
  border: solid 1px #ccc;
  border-radius: ${styleVariables.borderRadius};
  padding: 20px;
  height: fit-content;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

interface SCCProps {
  firstCardNumber: string;
}
const StyledCreditCard = styled.div<SCCProps>`
  width: 300px;
  height: 190px;
  background-color: #fff;
  border-radius: ${styleVariables.borderRadius};
  margin: 0 auto;
  position: relative;
  margin-bottom: 50px;
  box-shadow: 0 0 10px #ccc;
  text-shadow: 1px 1px 2px #00000080;
  color: #fff;
  ${({ firstCardNumber }) => {
    switch (firstCardNumber) {
      case '3':
        return css`
          background-image: linear-gradient(45deg, #2e77bb, #00b392);
          color: ${lighten(0.5, '#2E77BB')};
        `;
      case '4':
        return css`
          background-image: linear-gradient(45deg, #1a1f71, #3593bf);
          color: ${tint(0.9, '#1a1f71')};
        `;
      case '5':
        return css`
          background-image: linear-gradient(45deg, #ff5f00, #ffd066);
          color: ${tint(0.9, '#FF5F00')};
        `;
      default:
        return css`
          background-color: #666;
          color: ${tint(0.9, '#666')};
        `;
    }
  }};
`;

const StyledFormFlex = styled.div`
  display: flex;
  @media (max-width: 900px) {
    flex-flow: column;
  }
`;

interface SFFProps {
  flex: string;
}
const StyledFormField = styled(FormField)<SFFProps>`
  flex: ${({ flex }) => flex};
  margin-right: 15px;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 900px) {
    margin-right: 0;
  }
`;

const StyledFAIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const StyledCreditCardIcon = styled.div`
  position: absolute;
  bottom: 10px;
  right: 5px;
`;

const StyledCreditCardNumber = styled.div`
  position: absolute;
  bottom: 70px;
  left: 10px;
  font-size: 1.2em;
`;

const StyledCreditCardExpiryDate = styled.div`
  position: absolute;
  bottom: 35px;
  left: 10px;
  font-size: 0.9em;
`;

const StyledCreditCardName = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 0.9em;
`;
const StyledItemList = styled.div`
  max-height: 300px;
  overflow: auto;
  display: flex;
  flex-flow: column;
`;

const StyledTotals = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: 90px 1fr;
`;

const StyledEmptyMessage = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  svg {
    margin: 30px 0;
  }
`;
const Payment = () => {
  const [firstName, setFirstName] = useState('Your');
  const [lastName, setLastName] = useState('Name');
  const [CCNumber, setCCNumber] = useState('');
  const [CCNumberDisplay, setCCNumberDisplay] = useState('0000 0000 0000 0000');
  const [CVVCode, setCVVCode] = useState('');
  const [ExpiryDate, setExpiryDate] = useState('02/2999');
  const [listOfItems, setListOfItems] = useState([]);
  const toast = useToast();

  useEffect(() => {
    const localItems = localStorage.getItem('ItemsInBag');
    if (localItems) {
      const localItemsJson = JSON.parse(localItems);
      setListOfItems(localItemsJson);
    }
  }, []);

  const value = (listOfItems: any) => {
    let StartingValue = 0;
    listOfItems.map((item: any) => {
      const priceAsNumber = parseFloat(item.price.replace('$', ''));
      StartingValue += priceAsNumber;
    });
    return StartingValue;
  };
  return (
    <>
      <Layout hasHero={false}>
        <SEO title="Payment" />

        <HTag pageHeader>Payment</HTag>

        <StyledPaymentPage>
          <StyledOrderReview>
            <StyledItemList>
              {listOfItems.length > 0 ? (
                listOfItems.map((item: any, index: number) => {
                  return (
                    <CartItem
                      key={item.id + '_' + index}
                      item={item}
                      itemsInBag={listOfItems}
                      index={index}
                      setItemsInBag={(value) => setListOfItems(value)}
                    />
                  );
                })
              ) : (
                <>
                  <StyledEmptyMessage>
                    <FontAwesomeIcon
                      icon={faShoppingCart}
                      size="2x"
                      style={{ color: '#ccc' }}
                    />
                    No item added.
                  </StyledEmptyMessage>
                  <LinkButton
                    style={{
                      display: 'inline-block',
                      margin: '10px auto',
                    }}
                    to="/menu"
                  >
                    VIEW THE MENU
                  </LinkButton>
                </>
              )}
            </StyledItemList>
            {listOfItems.length > 0 && (
              <StyledTotals>
                <strong>Subtotal:</strong> {'$' + value(listOfItems).toFixed(2)}
                <br />
                <strong>Tax (13%): </strong> $
                {(value(listOfItems) * 0.13).toFixed(2)} <br />
                <strong>Total: </strong>$
                {(value(listOfItems) + value(listOfItems) * 0.13).toFixed(2)}
              </StyledTotals>
            )}
            <Alert
              style={{ marginTop: 20 + 'px' }}
              title="This is a Sample Form"
              fcStyle="info"
              icon="exclamation-circle"
            >
              Do not enter your real details into this form. It is just an
              example of what a order form would look like.
            </Alert>
          </StyledOrderReview>
          <div>
            <StyledCreditCard firstCardNumber={CCNumber.charAt(0)}>
              <StyledCreditCardName>
                {firstName} {lastName}
              </StyledCreditCardName>
              <StyledCreditCardNumber>
                {' '}
                {CCNumberDisplay.replace(/ /g, '').replace(/.{4}/g, '$&' + ' ')}
              </StyledCreditCardNumber>
              <StyledCreditCardExpiryDate>
                {ExpiryDate}
              </StyledCreditCardExpiryDate>
              <StyledCreditCardIcon>
                {CCNumber.charAt(0) === '4' && (
                  <StyledFAIcon size="2x" icon={faCcVisa} />
                )}
                {CCNumber.charAt(0) === '5' && (
                  <StyledFAIcon size="2x" icon={faCcMastercard} />
                )}
                {CCNumber.charAt(0) === '3' && (
                  <StyledFAIcon size="2x" icon={faCcAmex} />
                )}
                {CCNumber.charAt(0) != '3' &&
                  CCNumber.charAt(0) != '4' &&
                  CCNumber.charAt(0) != '5' && (
                    <StyledFAIcon size="2x" icon={faCreditCard} />
                  )}
              </StyledCreditCardIcon>
            </StyledCreditCard>
            <form onSubmit={(e) => e.preventDefault()}>
              <StyledFormFlex>
                <StyledFormField
                  htmlFor="FirstName"
                  flex=" 1 1"
                  label="First Name"
                >
                  <Input
                    id="FirstName"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </StyledFormField>
                <StyledFormField
                  htmlFor="LastName"
                  flex=" 1 1"
                  label="Last Name"
                >
                  <Input
                    id="LastName"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </StyledFormField>
              </StyledFormFlex>
              <StyledFormFlex>
                <StyledFormField
                  flex="1 1"
                  htmlFor="CCNumber"
                  label="Credit Card Number"
                >
                  <Input
                    type="text"
                    value={CCNumber}
                    onChange={(e) => {
                      if (e.target.value.replace(/\s/g, '').length > 16) {
                        e.preventDefault();
                      } else {
                        setCCNumber(e.target.value);
                        setCCNumberDisplay(e.target.value);
                        if (e.target.value.length === 0) {
                          setCCNumberDisplay('0000 0000 0000 0000');
                        }
                      }
                    }}
                    id="CCNumber"
                  />
                </StyledFormField>
                <StyledFormField
                  flex="0 0 100"
                  htmlFor="EXPDate"
                  label="Expiry Date"
                >
                  <Input
                    onChange={(e) => setExpiryDate(e.target.value)}
                    placeholder="MM/YYYY"
                    id="EXPDate"
                  />
                </StyledFormField>
                <FormField htmlFor="CVVCode" label="CVV Code">
                  <Input
                    onChange={(e) => setCVVCode(e.target.value)}
                    id="CVVCode"
                    value={CVVCode}
                  />
                </FormField>
              </StyledFormFlex>
              <p style={{ fontSize: '0.8em' }}>
                Enter a credit card number. A number starting with 3 is American
                Express, with 4 is Visa and with 5 is Mastercard."
              </p>
              <FormField label="Address" htmlFor="Address">
                <Input id="Address" />
              </FormField>
              <FormField
                label="Special Instructions"
                htmlFor="SpecialInstructions"
              >
                <Textarea id="SpecialInstructions" rows={15} />
              </FormField>
              <StyledButton
                style={{
                  width: 300 + 'px',
                  margin: '0 auto',
                  display: 'block',
                }}
                primary
                onClick={() => {
                  toast.addInfo(
                    'HEY THERE',
                    'If this was a real site this would go to a confirmation page where the user would be given information about their order.',
                    { id: 'one' }
                  );
                }}
              >
                PAY
              </StyledButton>
            </form>
          </div>
        </StyledPaymentPage>
      </Layout>
    </>
  );
};

export default Payment;
